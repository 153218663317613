<template>
  <div>
    <b-carousel
        id="botonesSuperiores"
        v-model="slide"
        :interval="0"
        controls
        img-height="300"
        style="text-shadow: 1px 1px 2px #333;"
    >
        <b-carousel-slide>
            <div class="logosSuperiores" v-bind:class="{ 'activo':$route.name=='datosBasicosVigilanciaControl'}">
                <div class="imagenDatosBasicos" @click="datosPersonales" ></div>
                <h5>Datos <br /> Básicos</h5>
            </div>

            <div class="logosSuperiores" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null, 'activo':$route.name=='materiaPrimaVigilanciaControl'}">
                <div class="imagenMateriaPrima" @click="materiaPrima" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null}"></div>
                <h5>Mat. Prima e <br />  Insumos</h5>
            </div>

            <div class="logosSuperiores" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarMateriaPrima, 'activo':$route.name=='infraestructuraServiciosVigilanciaControl'}">
                <div class="imagenInfraestructura" @click="infraestructuraServicios"  v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarMateriaPrima}"></div>
                <h5>Infraestructura <br /> Servicios</h5>
            </div>

            <div class="logosSuperiores" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarInfraServi,  'activo':$route.name=='energiaElectricaVigilanciaControl'}">
                <div class="imagenEnergiaElectrica" @click="energiaElectrica"  v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarInfraServi}"></div>
                <h5>Energía <br /> Eléctrica</h5>
            </div>

            <div class="logosSuperiores" v-bind:class="{'disabled': visitaEnGestion.num_acta==null || !validarEnergia, 'activo':$route.name=='vertimientoVigilanciaControl'}">
                <div class="imagenVertimiento" @click="vertimiento" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarEnergia}"></div>
                <h5>Vertimiento</h5>
            </div>
        </b-carousel-slide>

        <b-carousel-slide>
                    <div class="logosSuperiores" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarVertimiento, 'activo':$route.name=='emisionesAtmosfericasVigilanciaControl'}">
                        <div class="imagenEmisionesAtmosfericas" @click="emisionesAtmosfericas" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarVertimiento}" ></div>
                        <h5>Emisiones <br /> Atmosféricas</h5>
                    </div>

                    <div class="logosSuperiores" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarEmisiones, 'activo':$route.name=='residuosSolidosVigilanciaControl'}">
                        <div class="imagenResiduos" @click="residuosSolidos" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarEmisiones}" ></div>
                        <h5>Residuos <br /> Sólidos</h5>
                    </div>
                    
                    <div class="logosSuperiores" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarResiduosSolidos, 'activo':$route.name=='recursosnaturalesVigilanciaControl'}">
                        <div class="imagenRecursosNaturales" @click="recursosNaturales" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarResiduosSolidos}"></div>
                        <h5>Recursos <br /> Naturales</h5>
                    </div>
                    
                    <div class="logosSuperiores" v-bind:class="{'disabled': visitaEnGestion.num_acta==null || !validarRecursosNaturales, 'activo':$route.name=='listaChequeoVigilanciaControl'}">
                        <div class="imagenListaChequeo" @click="listaChequeo" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarRecursosNaturales}"></div>
                        <h5>Lista <br /> Chequeo</h5>
                    </div>

                    <div class="logosSuperiores" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarListaChequeo, 'activo':$route.name=='cierreVisitaVigilanciaControl'}">
                        <div class="imagenCierre" @click="cierreVisita" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarListaChequeo}"></div>
                        <h5>Cerrar <br /> Visita</h5>
                    </div>
        </b-carousel-slide>
    </b-carousel>
    <br />
    <br />
    <router-view />
  </div>
</template>

<script>
    import {mapActions, mapState} from 'vuex'
    export default {
        name: "FormularioGestionVisitaVigilanciaControl",
        data(){
            return{
                slide: 0,
            }
        },
        async mounted(){
            this.datosPersonales()
             if(this.visitaEnGestion.id){
                await this.cargarMateriaPrimaById(this.visitaEnGestion.id)
                await this.cargarInfraServiById(this.visitaEnGestion.id)
                await this.cargarEnergiaElectricaById(this.visitaEnGestion.id)
                await this.cargarVertimientoById(this.visitaEnGestion.id)
                await this.cargarEmisionesAtmosfericasById(this.visitaEnGestion.id)
                await this.cargarResiduosSolidosVCById(this.visitaEnGestion.id)
                await this.cargarRecursosNaturalesById(this.visitaEnGestion.id)
                await this.cargarListaChequeoById(this.visitaEnGestion.id)
            }
        },
        computed:{
            ...mapState('gestionarVisitas', ['visitaEnGestion']),
            ...mapState('gestionarVisitasVigilanciaControl', [
                    'emisionesAtmosfericasCargada',
                    'materiasPrimasCargada',
                    'infraServiCargada',
                    'energiaElectricaCargada',
                    'vertimientoCargado',
                    'residuosSolidosVCCargado',
                    'recursosNaturalesCargados',
                    'listaChequeoCargada',
            ]),

            validarMateriaPrima(){
               if(this.materiasPrimasCargada.length>0){
                   return true
               }
               return false
            },
            validarInfraServi(){
               if(this.infraServiCargada.length>0){
                   return true
               }
               return false
            },
            validarEnergia(){
               if(this.energiaElectricaCargada.length>0){
                   return true
               }
               return false
            },
            validarVertimiento(){
               if(this.vertimientoCargado.length>0){
                   return true
               }
               return false
            },
            validarEmisiones(){
               if(this.emisionesAtmosfericasCargada.length>0){
                   return true
               }
               return false
            },
            validarResiduosSolidos(){
               if(this.residuosSolidosVCCargado.length>0){
                   return true
               }
               return false
            },
            validarRecursosNaturales(){
               if(this.recursosNaturalesCargados.length>0){
                   return true
               }
               return false
            },
            validarListaChequeo(){
               if(this.listaChequeoCargada.length>0){
                   return true
               }
               return false
            },
        },
        methods:{
              ...mapActions('gestionarVisitasVigilanciaControl', [
                    'cargarEmisionesAtmosfericasById',
                    'cargarMateriaPrimaById',
                    'cargarInfraServiById',
                    'cargarEnergiaElectricaById',
                    'cargarVertimientoById',
                    'cargarResiduosSolidosVCById',
                    'cargarRecursosNaturalesById',
                    'cargarListaChequeoById',
              ]),
              
            datosPersonales(){
                this.$router.push({name: 'datosBasicosVigilanciaControl'})
            },
            materiaPrima(){
                if(this.visitaEnGestion.num_acta!=null){
                    this.$router.push({name: 'materiaPrimaVigilanciaControl'})
                }
            },
            infraestructuraServicios(){
                if(this.visitaEnGestion.num_acta!=null && this.validarMateriaPrima){
                    this.$router.push({name: 'infraestructuraServiciosVigilanciaControl'})
                }
            },
            energiaElectrica(){
                if(this.visitaEnGestion.num_acta!=null && this.validarInfraServi){
                    this.$router.push({name: 'energiaElectricaVigilanciaControl'})
                }
            },
            vertimiento(){
                if(this.visitaEnGestion.num_acta!=null && this.validarEnergia){
                    this.$router.push({name: 'vertimientoVigilanciaControl'})
                }
            },
            emisionesAtmosfericas(){
                if(this.visitaEnGestion.num_acta!=null && this.validarVertimiento){
                    this.$router.push({name: 'emisionesAtmosfericasVigilanciaControl'})
                }
            },
            residuosSolidos(){
                if(this.visitaEnGestion.num_acta!=null && this.emisionesAtmosfericas){
                    this.$router.push({name: 'residuosSolidosVigilanciaControl'})
                }
            },
            recursosNaturales(){
                if(this.visitaEnGestion.num_acta!=null && this.validarResiduosSolidos){
                    this.$router.push({name: 'recursosnaturalesVigilanciaControl'})
                }
            },
            listaChequeo(){
                if(this.visitaEnGestion.num_acta!=null && this.validarRecursosNaturales){
                    this.$router.push({name: 'listaChequeoVigilanciaControl'})
                }
            },
            cierreVisita(){
                if(this.visitaEnGestion.num_acta!=null && this.validarListaChequeo){
                    this.$router.push({name: 'cierreVisitaVigilanciaControl'})
                }
            },
        }
    };
</script>

<style scoped>

    .logosSuperiores {
        background: #fff;
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0px 0px 10px #555;
        text-align: center;
        width: 170px;
        height: 170px;
        display: inline-block;
        margin: 8px;
        vertical-align: middle;
    }

    .logosSuperiores > div {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }

    .logosSuperiores .imagenDatosBasicos {
        background: url("../../assets/iconosApp.png");
        background-position: -4px -90px;
        height: 66px;
        width: 73px;
        
    }
    
    .logosSuperiores .imagenMateriaPrima {
        background: url("../../assets/iconosApp.png");
        background-position: -76px -90px;
        height: 66px;
        width: 66px;
        
    }
    
    .logosSuperiores .imagenInfraestructura {
        background: url("../../assets/iconosApp.png");
        background-position: -145px -90px;
        height: 66px;
        width: 77px;
        
    }
    
    .logosSuperiores .imagenEnergiaElectrica {
        background: url("../../assets/iconosApp.png");
        background-position: -218px -90px;
        height: 66px;
        width: 41px;
        
    }

    .logosSuperiores .imagenVertimiento {
        background: url("../../assets/iconosApp.png");
        background-position: -262px -90px;
        height: 66px;
        width: 68px;
        
    }
    
    .logosSuperiores .imagenEmisionesAtmosfericas {
        background: url("../../assets/iconosApp.png");
        background-position: -331px -91px;
        height: 68px;
        width: 71px;
        
    }
    
    .logosSuperiores .imagenResiduos {
        background: url("../../assets/iconosApp.png");
        background-position: -405px -90px;
        height: 66px;
        width: 55px;
        
    }
    
    .logosSuperiores .imagenRecursosNaturales {
        background: url("../../assets/iconosApp.png");
        background-position: -463px -91px;
        height: 68px;
        width: 71px;
        
    }
    
    .logosSuperiores .imagenListaChequeo {
        background: url("../../assets/iconosApp.png");
        background-position: -10px -162px;
        height: 68px;
        width: 53px;
        
    }
    
    .logosSuperiores .imagenCierre {
        background: url("../../assets/iconosApp.png");
        background-position: -147px -162px;
        height: 66px;
        width: 55px;
        
    }

    .logosSuperiores h5{
        margin-top: 15px;
        font-size: 18px;
        color: #00763f;
        text-shadow: none;
        font-weight: 600;
    }

    .logosSuperiores .disabled{
        opacity: 0.5;
    }

    .logosSuperiores.disabled{
        background: #efefef;
    }
    
    .logosSuperiores.activo{
        background: #02ed7f;
    }

</style>